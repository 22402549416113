import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import {Button} from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
// import AudioPlayer from 'react-h5-audio-player';
import ReactPlayer from 'react-player'
import Webcam from "react-webcam";
// import Editor from 'react-simple-code-editor';
// import { highlight, languages } from 'prismjs/components/prism-core';
// import 'prismjs/components/prism-clike';
// import "prismjs/components/prism-c";
// import 'prismjs/components/prism-javascript';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'; 
// import Speech from './Speech'; 
import useUnload from './useUnload'; 
// import {render} from 'react-dom'; 
import AceEditor from 'react-ace'; 

import PageVisibility from 'react-page-visibility';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";


const Exam = props => {

   const [dbData, setDBData] = React.useState([]);
   const [lgShow, setLgShow] = React.useState(false);
   const [audioShow, setAudioShow] = React.useState(false);
   const [currentQn, setCurrentQn] = React.useState(0);
  //  const [answers, setAnswer] = React.useState([]);
   const [code, setCode] = React.useState(' ');
   const [subjects, setSubjects] = React.useState([]); 
   const [currentSubject, setCurrentSubject] = React.useState(0); 
   const [currentDuration, setCurrentDuration] = React.useState(0); 
   const [subjectsLoaded, setSubjectsLoaded] = React.useState(false); 
   const [seconds, setSeconds] = useState(0);
   const [isActive, setIsActive] = useState(false);
   const [submitExam, setSubmitExam] = useState(false);
   const [closeExam, setCloseExam] = useState(false);
   const [emailID, setEmailID] = useState(''); 
   const [access, setAccess] = useState(''); 
   const [testId, setTestId] = useState(0); 
   const [showMsg, setShowMsg] = useState(false); 
   const [visible, setVisible] = useState(true); 
   const [distraction, setDistraction] = useState(0); 
   const [distStart, setDistStart] = useState(0); 
   const [distTotal, setDistTotal] = useState(0); 


  const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
  };

  useUnload(e => {
    e.preventDefault(); 
    e.returnValue = '';
    
  }); 


  //timer functions 
  useEffect(() => {
    let interval = null;

    if (seconds === currentDuration && currentDuration > 0)  
      { setIsActive(false); setSubmitExam(true); }
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);

    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
      setSubmitExam(false); 
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, currentDuration]);


  function handleVisibilityChange(isVisible) {
    setVisible(!isVisible);
    if (!isVisible) {
      setDistraction( distraction + 1 );
      setDistStart(Math.floor(Date.now()/1000));
    }
    else
    {
      var currentTime = Math.floor(Date.now()/1000);
      var diff = currentTime - distStart;
      setDistTotal(distTotal + diff); 

    }
}


  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
      () => {

         const imageSrc = webcamRef.current.getScreenshot();

        fetch('/api/exam/saveimage', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstParam: {imageSrc}
          })
        })

      }, 
      [webcamRef]
  );

  React.useEffect( () => 
  {
    console.log("came here to get subjects list"); 

    setEmailID(props.location.state['userData'].email);
    setAccess(props.location.state['userData'].access);  
    setTestId(props.location.state['userData'].testId); 
    
    // console.log('In exam, user and email ', emailID, access); 
    // console.log('In exam, testId = props, state ', props.location.state['userData'].testId, testId); 
    
    fetch('/api/exam/getsubjectslist?testid=' + props.location.state['userData'].testId)
    .then((res) => res.json())
    .then((data) => {setSubjects(data); 
      //console.log(data); 
      setSubjectsLoaded(true); })
    .catch((err) => console.log(err)); 
  }, [props])


  React.useEffect( () => 
  {
    console.log("came to load subjects...."); 
    if (subjectsLoaded && currentSubject < subjects.length) {

      let strParams = '/api/examquestionsbysubject?testid=' + testId + '&subjectid=' + ((subjects[currentSubject]).subject_id) + '&userid=' + emailID + '&access=' + access;  
      //console.log(strParams);

      fetch(strParams)
      .then(res => res.json())
      .then((data) => {
        //console.log(data[0]);  
        // console.log(subjects[currentSubject].subject_description); 
        if (subjects[currentSubject].subject_description === 'LISTENING' ) setAudioShow(true);
        setDBData(data[0]);
        //initialize answers array
        // setAnswer([]); 
        // for(var i=0; i<data[0].length; i++) {
        //   setAnswer(answers => [...answers, ' ']);
        // }
        //reset counters and start timer again....
        setCurrentQn(0); 
        setSeconds(0);
        setCurrentDuration((subjects[currentSubject]).duration_min*60);
        setIsActive(true);
      })
      .catch(console.log);
 
    }
    else if (subjectsLoaded && currentSubject >= subjects.length) {
      //close the exam if all subjects are loaded and completed. 
      setCloseExam(true); 
    }

  }, [currentSubject, subjectsLoaded, subjects, testId]); 


  useEffect( () => 
  {
    if (submitExam) {
      setLgShow(false)
      console.log("Submitting exam results....");
      // console.log(dbData); 

        fetch('/api/exam/submitExam', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: {emailID},
            access: {access}, 
            testId: {testId}, 
            subjectId: subjects[currentSubject].subject_id, 
            questions: {dbData}, 
            // answers: {answers}
          })        
        })
        .then((response) => {
            if (response.ok) {
              console.log(currentSubject); 
              const tmp = currentSubject + 1; 
              setCurrentSubject(tmp); 
              console.log(currentSubject); 
            }
            setSubmitExam(false);
        })
        .catch((err) => console.log(err));
      } 
  }, [submitExam]); 


  useEffect( () => {
      if (closeExam) {

      let userData = {email:'', access:'', testId:0}; 

      userData.email = emailID; 
      userData.access = access;
      userData.testId = testId;  
      fetch('/api/exam/submitdistractioncounts', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: emailID,
          access: access, 
          dCt: distraction,
          dSecs:distTotal
        })        
      })
      .then((response) => {
          if (response.ok) {
            // console.log("submitted"); 
          }
      })
      .catch((err) => console.log(err));

        console.log("done with all subjects"); 
        if (testId !== '4' && testId !== '99') {
            if (testId === '5' || testId === '21') 
              props.history.replace("/thankyou");
            else
            props.history.replace("/thankyou2");
        }
        else 
          props.history.replace("/Checklist", {userData});
    }
    else setCloseExam(false);
  }, [closeExam, props, emailID, access, testId])


   
    //Update answers in state 
    function updateAnswer(event, questionNumber, userAnswer) {
      // const newAnswer = answers;
      // newAnswer[questionNumber] = userAnswer;
      // setAnswer(newAnswer); 

      const questions=dbData;
      questions[questionNumber].user_answer = userAnswer; 
      setDBData(questions); 

    };


    //toggle questions
    function handleQuestionChange(e, direction) {
         setCurrentQn(currentQn+direction);
    };


    //return question header and render the elements required. 
    function getHeaderByResourceType(d)
    {
        
        switch((d.resource_type).toUpperCase()) {
            case 'AUDIO':
              {
                return(
                  <audio controls controlsList="nodownload">
                     <source src={d.paragraph}/></audio>
                  );
                  // <AudioPlayer
                  // src={d.paragraph}
                  // autoPlay
                  // onPlay={e => e.preventDefault()}
                  // onPause={e => {e.preventDefault(); e.stopPropagation();}}

                  // other props here
                  // />
                  // <Speech text={d.paragraph}/>);
              }
            case 'VIDEO':
                return(
                <ReactPlayer url={d.paragraph} playing/>); 
            case 'IMAGE': 
                return( <Image src={d.paragraph} fluid/> ); 
            case 'PARA':
                return (
                    <>
                    {/* <Badge variant="secondary">READ ME</Badge><br/> */}
                    <div dangerouslySetInnerHTML={{ __html: d.paragraph }}></div>
                    {d.help_files != null ? <Image src={d.help_files} fluid/> : '' } </> 
                ); 
            default:
              return (<p>Oops. Something is not correct. Please contact administrator</p>);
        }
     };


     // d - question object; optString - string to be concatenated with the answer (choice like A,B,C or D)
  // currentAnswer - to make option button checked, choice field - the field in the object array to show as choice
  function getOptionButton(d, optString, currentAnswer, choice) {
    // console.log ("String=" + optString + "  Current Answer" + currentAnswer);
    if (optString === currentAnswer) 
      return (
        <Form.Check type="radio" id={`${d.question_number}${optString}`} name={`${d.question_number}`} label={`(${optString}) ${choice}`} 
          onChange ={e => updateAnswer(e, currentQn, optString)} checked />)
    else 
      return (
      <Form.Check type="radio" id={`${d.question_number}${optString}`} name={`${d.question_number}`} label={`(${optString}) ${choice}`} 
        onChange ={e => updateAnswer(e, currentQn, optString)} />)

  }

  function handlePaste(e)
  {
    e.text = ''; 
    e.event.preventDefault(); 
    return false; 
  }

  //render the question body - multiple choice or form or any other element
  function getContentByQuestionType(d) {
      switch(d.question_type) {
        case 'TEXTAREA' : {
          return (
            <FormControl id={d.question_number} as="textarea" rows="8" aria-label="With textarea" 
              onChange={(e) => updateAnswer(e, currentQn, e.target.value)}  onPaste={(e) => e.preventDefault()}/>
           );
        }
        case 'TEXT' : {
          return (
            <FormControl id={d.question_number} onPaste={(e) => e.preventDefault()} type="text"
             onChange={(e) => updateAnswer(e, currentQn, e.target.value)} />
           );
        }
        case 'RECORD': 
          return (
            <>
              <Webcam
                id={d.question_number}
                audio={true}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <Button onClick={capture}>Capture photo</Button>
            </>
          );
        case 'CODE' : {
          return(
            //  <Editor 
            //   id={d.question_number}
            //   value={code}
            //   onValueChange={(c) => setCode(c)}
            //   onChange={(e) => updateAnswer(e, currentQn, e.target.value)}
            //   highlight={c => highlight(c, languages.c)}
            //   padding={10}
            //   style={{
            //   fontFamily: '"Fira code", "Fira Mono", monospace',
            //   fontSize: 12,
            //   backgroundColor: "lightgrey"
            //   }}
            // />
            <AceEditor
            mode="javascript"
            theme="monokai"
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={d.user_answer}
            onChange={(newValue, e) => updateAnswer(e, currentQn, newValue)}
            name={d.question_number}s
            id={d.question_number}
            onPaste={handlePaste}
            width="100%"
            editorProps={{ $blockScrolling: true }}
           />
          );
        }
        case 'MC': {  //Multiple Choice Questions (//answers[currentQn])
          return (
            <> 
              {getOptionButton(d, 'A', d.user_answer, d.choice1)}
              {getOptionButton(d, 'B', d.user_answer, d.choice2)}
              {getOptionButton(d, 'C', d.user_answer, d.choice3)}
              {getOptionButton(d, 'D', d.user_answer, d.choice4)} 
            </>
          ); 
        }
        default: {
          return (<p>Oops. Something is not correct. Please contact administrator</p>)
        }

        }
      };

return (
 
  <>
    <br/>
    <Modal
      size="md"  show={lgShow} onHide={() => setLgShow(false)}>
          <Modal.Header closeButton>
              <Modal.Title id="closeExam">End Exam Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>This action will submit your test for evaluation and move to next section. 
            You will not be able to undo this activity.
            <br/><br/>Are you sure you want to Submit? 
          </Modal.Body>
          <Modal.Footer>
          <Button variant="contained" onClick={() => setLgShow(false)}>Cancel</Button>
          <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}}  onClick={() => {(currentSubject < subjects.length)?setSubmitExam(true):setCloseExam(true)}}>Submit Answers</Button>
        </Modal.Footer>
    </Modal>

    <Modal
      size="lg"  show={audioShow} onHide={() => setAudioShow(false)}>
          <Modal.Header>
              <Modal.Title id="audioShow">Audio Questions Section</Modal.Title>
          </Modal.Header>
          <Modal.Body><p>Below section contains listening excercises. Please use your headphones or computer speaker to listen 
            to the audio clip and answer the questions that are dependent on that audio clip. </p>
            <br/> 
            <p>There will be multiple audio clips. 
            Wheenver the audio clip changes, the play link gets activated. Close this window and "play" the audio whenever you are ready. </p>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}}  onClick={() => setAudioShow(false)}>CLOSE</Button>
        </Modal.Footer>
    </Modal>

    <PageVisibility onChange={handleVisibilityChange}> 
    
    <Container fluid={true}>
      <Form id="mainForm">
        <Row> 
          <Col align="left" className="text-muted">
              <h6>SUBJECT - {(typeof(subjects[currentSubject]) != 'undefined' && subjects[currentSubject] != null) ? subjects[currentSubject].subject_description: ''}
              </h6>
          </Col> 
          <Col align="right" className="text-muted">
             <h6> Elapsed Time: {String(Math.trunc(seconds/60)).padStart(2, '0')}:{String(seconds%60).padStart(2,'0')} of {String(currentDuration/60).padStart(2, '0')}:{String(currentDuration%60).padStart(2, '0')}</h6>
          </Col>

        </Row>
        <Row><br></br>
        </Row>
        
        {dbData.slice(currentQn, currentQn+1).map((d) => 
      
        <Card key={d.question_number} bg="light">
          <Card.Header>
                <div>{getHeaderByResourceType(d)}</div>
          </Card.Header>
   
          <Card.Body>
            <Card.Title as="h6" style={{color:'blue'}}>{d.question}</Card.Title>
            <Card.Text style={{fontSize:'8'}}>
             {getContentByQuestionType(d)} 
            </Card.Text>
          </Card.Body>

          <Card.Footer className="text-muted">
            <Row>
            <Col align="left">
            <Button variant="outlined" disabled={currentQn === 0 ? true: false}
              onClick={(e) => handleQuestionChange(e, -1)}>Previous Question
            </Button>
            {' '}
            <Button variant="outlined" 
                  disabled={currentQn === (dbData.length-1) ? true: false}
                  onClick={(e) => handleQuestionChange(e, 1) }>Next Question
            </Button>
            </Col>
            <Col align="right">
            <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}} 
            onClick={() => setLgShow(true)}>Submit Answers</Button>
          </Col>
          </Row>
          </Card.Footer>
        </Card>
      )}
      </Form>
      <h6 style={{marginTop: '20px', color: 'grey'}}>Distraction Count: {distraction} for {distTotal} seconds </h6>
    </Container>
    </PageVisibility>
    </>
  );
}

export default Exam;

