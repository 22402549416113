import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

class Home extends Component {

  render() {
    return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("./res/Photo-1.png")} 
          alt="Measure"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("./res/Photo-2.png")} //holder.js/800x400?text=Second slide&bg=282c34"
          alt="Magnify"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require("./res/Photo-3.png")} //"holder.js/800x400?text=Third slide&bg=20232a"
          alt="Materialize"
        />

        {/* <Carousel.Caption>
          <h1 style={{color:"orange"}}>MATERIALIZE</h1>
          <p>Materialize your dream job by completing ELP and get placed.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>

      )
  }
}

export default Home;