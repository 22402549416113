import React, { Component} from 'react';
// import Json from './form.json';
import {Form, Row, Col, Container, Card, Accordion} from 'react-bootstrap';
import Button from '@material-ui/core/Button'; 
import MasterData from './MasterData.json'; 
import Timer from 'react-compound-timer'; 
import MultiRef from 'react-multi-ref'; 

class GeneralData extends Component {

    constructor(props) {
        super(props); 
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.dataRef = new MultiRef();

        this.state = {email: '', access: '', testId:0, validated:false, currentPage:0, totalPages:0, formJSON: []};
        this.handlePageChange = this.handlePageChange.bind(this); 
      

    }

    componentDidMount() {
        this.setState({email: this.props.location.state['data'].email, access: this.props.location.state['data'].access
          , testId: this.props.location.state['data'].testId }); 
          fetch('/api/exam/getTemplateFields?template_id=' + this.props.location.state['data'].templateId)
          .then((res) => res.json())
          .then((data) => {
            //console.log('form structure',data); 
            this.setState({formJSON: data})
            this.setState({totalPages: [...new Set(data.map(item => item.field_group))].length});
           })
          .catch((err) => console.log(err)); 


        //find out total number of pages 
        //this.setState({totalPages: [...new Set(Json.map(item => item.field_group))].length});
    }

    handleSubmit(e) {

      // const form = e.currentTarget;  

      // let items = this.state;
      // console.log('validated=', items['validated']); 
      // items['validated'] = true;
      // this.setState(() => ({
      //     items
      // }));


      // if (form.checkValidity() === true) 
      
      // {
        // console.log('in true validated. '); 
        //return all the values to servrer and submit the answers. 
        console.log('Submitting Results to Server.'); 
        // var data = Json; 
        // this.dataRef.map.forEach((item, i) => {console.log(item); (data[i-1]).value = item.value});

          //update Json with form data
          this.dataRef.map.forEach((item, i) => {
            //   for (var k = 0; k < Json.length; k++){
            //     if (item.id === Json[k].field_id ){
            //         Json[k].value = item.value;
            //         break;
            //     }
            // }
            let tempJson = this.state.formJSON;
            for (var k = 0; k < tempJson.length; k++){
              if (item.id === (tempJson[k]).field_id ){
                tempJson[k].value = item.value;
                  break;
              }
          }
          this.setState({formJSON : tempJson})
          })

        // var data = Json;
        var data = this.state.formJSON;
        //console.log(data);

            fetch('/api/exam/submitGeneralData', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: this.state.email, 
                access: this.state.access, 
                testId: this.state.testId, 
                data:{data}
              })        
            })
            .then((response) => {
                if (response.ok) {
                  let userData = {email:'', access:'', testId:0}; 
                  userData.email = this.state.email; 
                  userData.access = this.state.access;
                  userData.testId = this.state.testId; 
                  //console.log('testId in General Data=', this.state.testId); 
                  this.props.history.replace("/Exam", {userData});
                }
            })
            .catch((err) => console.log(err)); 


          // }
          // else {
          //   e.preventDefault(); 
          //   e.stopPropagation();       
          // }
      };


  getRowSource(d) {
    return(
        (MasterData.filter(item => item.dataset === d.rowsource)).map(key => (
        key.data.map((pair) => <option key={`${d.field_id}+${pair.key}`}>{pair.value}</option>)
      ))
    )
  }

  getFormFragment(d) {

    //   console.log(d.component); 
      if (d.component === 'email' || d.component === 'password' || d.component === 'text')  {
        return(
          <Col key={`COL${d.field_id}`} xs={d.colspan}>
          <Form.Group key={`GRP${d.field_id}`} controlId={`GRP${d.field_id}`}>
          <Form.Label>{d.field_description}{d.required === true ? '*': ''}</Form.Label>
          <Form.Control id={`${d.field_id}`} ref={this.dataRef.ref(d.unique_id)}  type={d.component} placeholder={d.placeholder} 
          required={d.required} />
          <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
          {/* <Form.Text className="text-muted">
            {d.help_text}
          </Form.Text> */}
          </Form.Group>
          </Col>
        );   
      } 
      else if (d.component === 'password') {
        return(
          <Col key={`COL${d.field_id}`} xs={d.colspan}>
          <Form.Group key={`GRP${d.field_id}`} controlId={`${d.field_id}`}>
          <Form.Label>{d.field_description}{d.required === true ? '*': ''}</Form.Label>
          <Form.Control required={d.required} id={`${d.field_id}`} ref={this.dataRef.ref(d.unique_id)} type={d.component} placeholder={d.placeholder}/>
          {/* <Form.Text className="text-muted">
            {d.help_text}
          </Form.Text> */}
          </Form.Group>
          </Col>
        );
      } 
      else if (d.component === 'textarea') {
        return(
          <Col key={`COL${d.field_id}`} xs={d.colspan}>
          <Form.Group key={`GRP${d.field_id}`} controlId={`${d.field_id}`}>
          <Form.Label>{d.field_description}{d.required === true ? '*': ''}</Form.Label>
          <Form.Control required={d.required} as="textarea" ref={this.dataRef.ref(d.unique_id)} rows={d.rowspan} type={d.component} placeholder={d.placeholder}/>
          {/* <Form.Text className="text-muted">
            {d.help_text}
          </Form.Text> */}
          </Form.Group>
          </Col>
        );
      }    
      else if (d.component === 'select' || d.component === 'dropdown' || d.component=== 'multiselect') {

        return(
          <Col key={`COL${d.field_id}`} xs={d.colspan}> 
            <Form.Group key={`GRP${d.field_id}`}  as={Col} controlId={`${d.field_id}`} style={{paddingLeft:0, paddingRight:0}}>
              <Form.Label>{d.field_description}{d.required === true ? '*': ''}</Form.Label>
              <Form.Control required={d.required} as="select" ref={this.dataRef.ref(d.unique_id)}  >
                <option key={`{d.field_id}`} value="default">Choose...</option>
                {this.getRowSource(d)}
              </Form.Control>
              {/* <Form.Text className="text-muted">
              {/* {d.help_text} */}
              {/* </Form.Text>  */}
            </Form.Group>
          </Col>
        );
      } 
      else if (d.component === 'date') {
        return(
          <Col key={`COL${d.field_id}`} xs={d.colspan}>
          <Form.Group key={`GRP${d.field_id}`}  as={Col} controlId={`${d.field_id}`} style={{paddingLeft:0, paddingRight:0}}>
          <Form.Label>{d.field_description}{d.required === true ? '*': ''}</Form.Label>
          <Form.Control min="1950-01-01" max="2999-12-31" required={d.required} ref={this.dataRef.ref(d.unique_id)}  type={d.component} placeholder={d.placeholder} />
            {/* <DatePicker key={`${d.field_id}`} id={`${d.field_id}`}/> */}
            {/* <Form.Text className='text-muted'>{d.help_text}</Form.Text> */}
          </Form.Group>
          </Col>
        ); 
      }
      else {
        return(<label key={`${d.field_id}`} id={`${d.field_id}`}>Element not defined.</label>);
      }
  }


  handlePageChange(e, direction) {

        const form = e.currentTarget;  


        // //update Json with form data
        // this.dataRef.map.forEach((item, i) => {
        //   for (var k = 0; k < Json.length; k++){
        //     if (item.id === Json[k].field_id ){
        //         Json[k].value = item.value;
        //         break;
        //     }
        // }})

        let tempJson = this.state.formJSON;
        //update Json with form data
        this.dataRef.map.forEach((item, i) => {
          for (var k = 0; k < tempJson.length; k++){
            if (item.id === tempJson[k].field_id ){
              tempJson[k].value = item.value;
                break;
            }
        }})
        this.setState({formJSON: tempJson})        
        //set form validation to true 
        let items = this.state;
        //console.log('validated=', items['validated']); 
        items['validated'] = true;
        this.setState(() => ({
            items
        }));

        e.preventDefault(); 

        if (form.checkValidity() === true) {
          let page = this.state.currentPage;
          
          if (page < this.state.totalPages - 1) 
          {
            page = page + direction; 
            this.setState({currentPage: page}); 

            //set form validation to false
            let items = this.state;
            //console.log('validated=', items['validated']); 
            items['validated'] = false;
            this.setState(() => ({
                items
            }));
          }
          else {
            this.handleSubmit(); 
          }
        }
        else{
          e.stopPropagation();       
        }
  
  }


  render() {
    return (
      <Container fluid>
        <> 

        <br/> 
                {/* <Row>
                <Col align="left" className="text-muted">
                </Col>
                <Col align="right"><Button variant="contained" style={{backgroundColor:'teal', color: 'white'}}  onClick={this.handleSubmit}>Start Test</Button>
                </Col>
                </Row>
                <br/> */}
        {[...new Set(this.state.formJSON.map(item => item.field_group))].slice(this.state.currentPage, this.state.currentPage+1).map((item, i) =>
            <>
            <Form noValidate validated={this.state.validated} onSubmit={(e) => this.handlePageChange(e, 1)}>
            <Card>
            <Card.Header>
                <h6>{item.toUpperCase()}</h6></Card.Header>
            <Card.Body>
            <Row>
                {this.state.formJSON.filter((q) => (q.field_group === item)).map((d) => 
                  this.getFormFragment(d)
                )}
            </Row>
            </Card.Body>
            <Card.Footer>
              <Button variant="contained"  style={{backgroundColor:'teal', color: 'white'}}  type="submit">
                {(this.state.currentPage === this.state.totalPages-1) ? 'SUBMIT PERSONAL DATA' : 'NEXT PAGE'}
              </Button>
            </Card.Footer>
            </Card>
            </Form>
            </>
          )
        }
     </>
    </Container>

    );
  }
}

export default GeneralData;