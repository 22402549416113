import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'; 

export default function ErrorHandler() {
  return (
    <Jumbotron>
    <h1 className="text-muted">Oops. Something went wrong.</h1>
    <h6>
        Dear User, something went wrong while processing your request. This might be due to unauthorized access to a page. 
        You might have refreshed this page or pressed back button. Your data is lost. Please restart the exam.
    </h6><br/>
    </Jumbotron>
  );
}