import React from 'react'; 
import {Button} from '@material-ui/core'; 
import Jumbotron from 'react-bootstrap/Jumbotron'; 
import Speech from './Speech'; 


class VideoOutput extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        const videoObj = this.videoRef.current;
        videoObj.srcObject = this.props.video;
        videoObj.play();
    }

    render() {
        return <div align="center"><video width="320" height="320" ref={this.videoRef} autoPlay muted="muted"></video></div>; 
    }
}


class Video extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      video: null, 
      videoURL: null, 
      chunks: [], 
      email: '', 
      access: '', 
      testId: 0, 
      showButton: true
     };  

    this.mediaRecoder = null;
    this.handleVideoClick = this.handleVideoClick.bind(this);

    window.addEventListener("beforeunload", (ev) => {
      console.log("came here to unload event..."); 
      ev.preventDefault();
      ev.returnValue = '';
    });
  }


  componentDidMount() {
    try {
      this.setState({email: this.props.location.state['userData'].email, access: this.props.location.state['userData'].access
       , testId: this.props.location.state['userData'].testId }); 
   }
   catch (error) {
      //this.props.history.replace('/error'); 
   }      
  }

  async getVideo() {
    const video = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    });

    this.mediaRecorder = new MediaRecorder(video, {mimeType : 'video/webm;codecs=vp8,opus'});
    
    this.mediaRecorder.onstop = ((e) => {
      const blob = new Blob(this.state.chunks, { "type" : "video/webm;codecs=vp8,opus" });

      console.log(blob); 
      var data = new FormData(); 
      var fileName = this.state.email + '.webm'; 
      data.append("data", blob, fileName); 

      fetch('/api/exam/saveVideo', {
        method: 'POST',
        body: data
        }).then((response) => {
          if (response.ok) this.props.history.replace("/thankyou")
        })
        .catch((err) => console.log(err));
  
      this.setState({chunks: []});  
      const videoURL = window.URL.createObjectURL(blob);
      this.setState({videoURL: videoURL});
    })
    
    this.mediaRecorder.ondataavailable = ((e) => {
      this.setState( prevState => ({ chunks: [...prevState.chunks, e.data] }))
    }); 
    
    this.mediaRecorder.start();
    console.log(video); 
    this.setState({ video: video });
  }

  stopVideo() {
    console.log(this.state.video); 
    this.state.video.getTracks().forEach(track => track.stop());
    this.mediaRecorder.stop();
    this.setState({ video: null });
  }


  handleVideoClick() {
    if (this.state.video) {
      this.setState({showButton: false}); 
      this.stopVideo();
    } else {
      this.getVideo();
    } 
  }

  render(){
    return(
      <Jumbotron>
      <div align="right">
          <Button variant="contained" disabled={!this.state.showButton} style={{backgroundColor:'teal', color: 'white'}}   onClick={this.handleVideoClick}>
            {this.state.video  ? 'Stop Recording' : 'Start Recording'}
          </Button>
          {/* {this.state.videoURL ?  <Button variant="contained"  color="primary"  href={this.state.videoURL}>Download Recording</Button> : ''} */}
      </div>
      <h1 className="text-muted">Record Video</h1><br/>
      <h6>
          Dear patron, thank you for reaching the final leg of this test. When you are ready, press the button below to start 
          recording a video to explain about yourself. Once you are done with your recording, press stop recording to submit
          the results.  
      </h6><br/>
        {this.state.video ? <VideoOutput video={this.state.video} /> : ''}
      </Jumbotron>
    );
  }


}

export default Video; 