import React, { useRef, useEffect } from 'react';

const useUnload = fn => {
  const cb = useRef();

  cb.current = fn;

  useEffect(() => {
    const onUnload = (e) => cb.current(e);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, [cb]);
};

export default useUnload;