import React from 'react';
import { PythonProvider } from 'react-py'
import Codeblock  from './CodeBlock';

const PythonExam = props =>  {
  return (
    // Add the provider to your app
    <PythonProvider>
      <Codeblock />
    </PythonProvider>
  )
}

export default PythonExam;