// Register.js

import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'; 
import {Button} from '@material-ui/core';

class Register extends Component {
  render() {
    return (
        <>
        <Jumbotron>
        <h1 className="text-muted">Register for ELP Pre-assessment</h1><br/><br/>
        <h4>
            Dear patron, we would like to appreciate your interest in taking Pragmatiq pre-assessment. Please fill in below details to schedule
            for the exam.  
        </h4><br/><br/>
        <h4>
            You will be provided with an access code 30 minutes before the exam via an e-mail. This is required for you to start the exam. 
         </h4><br/><br/>
        <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}} >Register</Button>
        </Jumbotron>
    </>
    );
  }
}

export default Register;