import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Exam from './Exam';
import Navbar from 'react-bootstrap/Navbar';
import StartTest from './StartTest';
import PythonTest from './PythonTest';
import Thankyou from './Thankyou';
import Home from './Home';
import Register from './Register';
import Checklist from './Checklist';
import {AppBar, Toolbar, IconButton, Typography, Button} from '@material-ui/core'; 
import GeneralData from './GeneralData';
import ErrorHandler from './ErrorHandler'; 
import Video from './Video'; 
import SpeechTest from './SpeechTest'; 
import About from './About';
import Thankyou2 from './Thankyou2'; 
import PythonExam from './PythonExam'; 


class App extends Component {
  render() {
    return (
      <>
      <Router>
      <div style={{flexGrow: 1}}>
      <AppBar position="static" color="primary" >
      {/* style={{backgroundColor:'teal'}} */}
        <Toolbar>
        {/* <IconButton edge="start" size="small" style={{backgroundColor: 'white'}}> */}
          <img src="Pragmatiq_Full_logo.png" width="64" height="34"/>
          {/* <img src="pragmatiq_logo.png" width="64" height="34"/> */}
        {/* </IconButton> */}
        <Typography variant="h6" style={{flexGrow: 1}}>
             &emsp; PRAGMATIQ EVALUATION PORTAL
             {/* &emsp; PRAGMATIQ ASSESSMENT PORTAL */}
        </Typography>

        <Typography style={{flexGrow: 1}} align="right">
          {/* <Button href="/" style={{ backgroundColor: 'transparent' }} color="inherit">HOME</Button> */}
          <Button href="/starttest"style={{ backgroundColor: 'transparent' }} color="inherit">START TEST</Button>
          {/* <Button href="/pytest"style={{ backgroundColor: 'transparent' }} color="inherit">PYTHON TEST</Button>
          <Button href="/register" style={{ backgroundColor: 'transparent' }} color="inherit">REGISTER</Button> */}
          </Typography>

        </Toolbar>
      </AppBar>
      </div>
      <Switch>
          <Route exact path='/' component={Home}/>
          <Route path='/starttest' component={StartTest}/>
          <Route path='/pytest' component={PythonTest}/>
          <Route path='/Exam' component={Exam} />
          <Route path='/PythonExam' component={PythonExam} />
          {/* <Route path='/about' component={About} /> */}
          <Route path='/thankyou' component={Thankyou}/>
          <Route path='/thankyou2' component={Thankyou2}/>
          <Route path='/register' component={Register}/>
          <Route path='/checklist' component={Checklist}/>
          <Route path='/general' component={GeneralData}/>
          <Route path='/error' component={ErrorHandler}></Route>
          <Route path='/video' component={Video}></Route>
          {/* <Route path='/speech' component={SpeechTest}></Route> */}
      </Switch>

        <Navbar sticky="bottom"  className='d-flex justify-content-center'>
        <div>
          <p align="center" className="text-muted">
          <small>(C) 2022, Pragmatiq Systems Inc - All rights reserved. Pragmatiq Evaluation Portal</small>
          {/* <small>(C) 2020, Pragmatiq - All rights reserved. Pragmatiq Testing Module</small> */}
          </p>
        </div>
      </Navbar>
      </Router>
      </>
    );
  }
}

export default App;