import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'; 
import {Button} from '@material-ui/core';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {Paper,  Container} from '@material-ui/core'; 
import {TableContainer, Table, TableRow, TableCell, TableBody, TableHead} from '@material-ui/core'; 
const StartTest = props =>  {

  const [emailId, setEmailId] = React.useState('');
  const [accessCode, setAccessCode] = React.useState('');
  const [lgShow, setLgShow] = React.useState(false);  
  const [mdShow, setMdShow] = React.useState(false);
  const [testId, setTestId] = React.useState(0); 


  function handleClick(e) {
    console.log("Verifying access key....");

    e.preventDefault();

    let data = {email:'', access:''}; 

    data.email = emailId; 
    data.access = accessCode;

    console.log(data);

    fetch('/api/exam/login', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data
      })        
    })
    .then((res) => res.json())
    .then((data) => {console.log(data); 
        if (data === 'Not Authorized.') setMdShow(true) 
        else { setLgShow(true); setTestId(data);}
    })
    .catch((err) => console.log(err)); 
  }

  function handleExam(e) {
    let data = {email:'', access:'', testId:0}; 

    data.email = emailId; 
    data.access = accessCode;
    data.testId = testId;
    console.log('Test Id = ', testId); 
    e.preventDefault();
    if (testId === '99') {props.history.replace("/general", {data});}
    else {
      let userData = {email:'', access:'', testId:0}; 

      userData.email = emailId; 
      userData.access = accessCode;
      userData.testId = testId;

      props.history.replace("/Exam", {userData});
    }
  }

      return (
        <>
        <Modal
        size="md"
        show={lgShow}
        onHide={() => setLgShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title id="closeExam">Authorization Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your e-mail address and access code has been validated.
            <br/><br/>Are you sure you want to start the Exam? 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained"  onClick={() => setLgShow(false)}>Cancel</Button>
            <Button variant="contained"  style={{backgroundColor:'teal', color: 'white'}}  onClick={handleExam}>Start Exam</Button>
          </Modal.Footer>
        </Modal>

        <Modal
        size="md"
        show={mdShow}
        onHide={() => setMdShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title id="closeExam">Authorization Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your e-mail address and access code combination is not correct.
            <br/><br/>Verify the credentials you have received along with the alotted time slot and try again. 
            <br/>The code is activated only 30 minutes before the exam. If not registered with Pragmatiq for a test,
             please register for a test using <a href="/register">registration link</a>. 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}} onClick={() => setMdShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Jumbotron>
        <h1 className="text-muted">Start Your Test</h1>
        <h6>
            {/* Dear user, thank you for registering for Pragmatiq Pre-assessment. This is a clear first step towards 
            achieving your goal of completing a project internship or getting employed. All the best. 
            Please enter the approval code received from PRAGMATIQ below. */}
           Dear User, Thank you for enrolling in the Pragmatiq Pre-assessment. This is a crucial first step towards achieving your internship or employment goals. <br/>Best Wishes! Kindly input the PRAGMATIQ approval code below.
        </h6><br/>
        <TableContainer >
        <Table >  
          <TableBody>
                    <TableRow key='row1'>
                                <TableCell style={{width:"400px"}}>
      <Form id="frmLogin" onSubmit={(e) => handleClick(e)}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control onChange={(e) => setEmailId(e.target.value)} type="email" size="lg" value={emailId} placeholder="Enter email" style={{width:"30%"}} />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formAccessToken">
          <Form.Label>Access Key</Form.Label>
          <Form.Control type="text" size="lg" placeholder="Enter your access token" text={accessCode} style={{width:"30%"}} onChange={(e)=> setAccessCode(e.target.value)}/>
          <Form.Text className="text-muted">
            This is case-sensitive. 
          </Form.Text>
        </Form.Group>
        <Button variant="contained" style={{backgroundColor:'teal', color: 'white'}} type="submit">Authorize</Button> {'  '}
      </Form></TableCell>
                                <TableCell style={{width:"200px", alignItems:"center", alignContent:"top"}}><img src="rvrjc.png" width="250" height="250" alt="RVRJC _LOGO"/></TableCell>
                            </TableRow>
                         
            </TableBody>        
                    </Table> 
          </TableContainer>
        
      
        </Jumbotron>
      </>
    
    );

}
  
export default StartTest;