// // thankyou.js

// import React, { Component } from 'react';
// import Jumbotron from 'react-bootstrap/Jumbotron'; 

// class Thankyou2 extends Component {
//   render() {
//     return (
//         <>
//         <Jumbotron style={{height:"100vh"}}>
//         <h1 className="text-muted">Thank you!</h1><br/><br/>
//         <h4>
//             Dear User, Thank you for completing internal assessment. This is part of continuous evaluation 
//             during your current course. 
//         </h4><br/><br/>
//         <h4>
//             You will be notified of results in subsequent learning sessions.  
//         </h4><br/><br/>
//         {/* <Button variant="contained" color="primary">Leave your Feedback</Button> */}
//         </Jumbotron>
//     </>
//     );
//   }
// }

// export default Thankyou2;
// thankyou.js

import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'; 

class Thankyou2 extends Component {
  render() {
    return (
        <>
        <Jumbotron style={{height:"100vh"}}>
        <h1 className="text-muted">Thank you!</h1><br/><br/>
        <h4>
            Dear User, Thank you for taking the assessment. This is part of continuous evaluation 
            during your current course. 
        </h4><br/><br/>
        <h4>
            You will be notified of results in subsequent learning sessions.  
        </h4><br/><br/>
        {/* <Button variant="contained" color="primary">Leave your Feedback</Button> */}
        </Jumbotron>
    </>
    );
  }
}

export default Thankyou2;