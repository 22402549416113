// thankyou.js

import React, { Component } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'; 

class Thankyou extends Component {
  render() {
    return (
        <>
        <Jumbotron style={{height:"100vh"}}>
        <h1 className="text-muted">Thank you!</h1><br/><br/>
        <h4>
            Dear User, we would like to thank you for taking Pragmatiq pre-assessment. This is a clear first step towards 
            achieving your goal of getting employed. 
        </h4><br/><br/>
        <h4>
            We know that you are eager to know your test scores. 
            You will be notified through an e-mail shortly with the results and analysis. 
        </h4><br/><br/>
        {/* <Button variant="contained" color="primary">Leave your Feedback</Button> */}
        </Jumbotron>
    </>
    );
  }
}

export default Thankyou;