import React, {Component} from 'react';
import Questions from './EPIQuestions.json';
import Slider from '@material-ui/core/Slider';
import MultiRef from 'react-multi-ref'; 
import {Row, Col} from 'react-bootstrap';
import {Paper, Button, Container} from '@material-ui/core'; 
import {TableContainer, Table, TableRow, TableCell, TableBody, TableHead} from '@material-ui/core'; 
import Timer from 'react-compound-timer';

class Checklist extends Component {
    constructor(props) {
        super(props); 
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.sliderRef = new MultiRef();
        this.state = {email: '', access: '', testId:0};

    }

    componentDidMount() {
        try {
           this.setState({email: this.props.location.state['userData'].email, access: this.props.location.state['userData'].access
            , testId: this.props.location.state['userData'].testId }); 
        }
        catch (error) {
           this.props.history.replace('/error'); 
        }

    }

    handleSubmit(e) {
        //return all the values to servrer and submit the answers. 
        // console.log('Submitting Results to Server.'); 
        var qns = Questions; 
        this.sliderRef.map.forEach((item, i) => (qns[i-1]).selected = (item.getElementsByTagName("input")[0]).value)
        // console.log(qns);

        fetch('/api/exam/submitEPIData', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: this.state.email, 
              access: this.state.access, 
              testId: this.state.testId, 
              data: {qns}
            })        
          })
          .then((response) => {
              if (response.ok) {
                  if(this.state.testId === '99')
                  {
                    this.props.history.replace("/thankyou2");
                  }else
                  {
                    let userData = {email:'', access:'', testId:0}; 
                    userData.email = this.state.email; 
                    userData.access = this.state.access;
                    userData.testId = this.state.testId;  
                    this.props.history.replace("/Video", {userData});
                  }
                
              }
            })
          .catch((err) => console.log(err)); 

    }
        
    render() {
        return (
            <Container>
                <>
                <br/> 
                <Row>
                <Col align="left" className="text-muted">
                    <Timer initialTime={600000} direction="backward"
                    checkpoints={[
                    {
                        time: 0,
                        callback: (e) => this.handleSubmit(e)
                    }]}
                    >
                    <Timer.Hours formatValue={value => `Remaining: ${value} HH `} />
                    <Timer.Minutes formatValue={value => `${value} MM `} />
                    <Timer.Seconds formatValue={value => `${value} SS `}/>
                </Timer>
                </Col>
                <Col align="right"><Button variant="contained" style={{backgroundColor:'teal', color: 'white'}}  onClick={this.handleSubmit}>Submit Answers</Button>
                </Col>
                </Row>
                <br/>
                {[...new Set(Questions.map(item => item.section))].map(item =>  
                    <><TableContainer component={Paper}>
                    <Table style={{minWidth:300}} size="small">  
                    <TableHead>
                    <TableRow style={{background: 'linear-gradient(45deg, #bdbdbd 30%, #757575 90%)'}}>
                        <TableCell colSpan={2} style={{height:'auto !important'}}>PRAGMATIQ PERSONALITY INVENTORY - {item}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {Questions.filter((q) => (q.section === item)).map((qn) => 
                            <TableRow key={`TableRow${qn.id}`}>
                                <TableCell>{qn.question}</TableCell>
                                <TableCell style={{width:"400px"}}><Slider
                                    id={`Sl_${qn.id}`}
                                    ref={this.sliderRef.ref(qn.id)}
                                    key={`Sl_${qn.id}`}
                                    track={false}
                                    marks={qn.scale}
                                    min={qn.scaleMin}
                                    max={qn.scaleMax}
                                    step={null}
                                    style={{width:"300px"}}
                                   /></TableCell>
                            </TableRow>
                         )}
                    </TableBody>
                    </Table>  
                    </TableContainer>
                    <br/>
                    </>
                )}
                </>
            </Container>
        ) //return 
     } //render
}


export default Checklist;